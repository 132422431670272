import React, { useMemo } from "react"
import LayoutRoot from "../components/LayoutRoot"
import Seo from "../components/seo"
import styled from "styled-components"
import { FlexContainer } from "../styles/layout.styled"

import BlogCard from "../components/reusable/BlogCard"
import { useInView } from "react-intersection-observer"
import { graphql } from "gatsby"
import useViewMore from "../hooks/useViewMore"
import { filterDummyBlog } from "../utils"

const BlogPageContainer = styled(FlexContainer)`
  flex-direction: column;
`

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 70px 0;
  color: ${({ theme }) => theme.white};
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
    inView ? "translateX(0) scale(1)" : "translateX(-500px) scale(0)"};
  transition: all 1s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0) scale(1)" : "translateX(-250px) scale(0)"};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0) scale(1)" : "translateX(-100px) scale(0)"};
  }
`

const BlogsContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.white};
  flex-direction: column;
`

const BlogsInnerContainer = styled(FlexContainer)`
  width: 100%;
  max-width: 1026px;
  flex-direction: row;
  justify-content: space-between;
  padding: 71px 20px 120px;
  gap: 36px;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    max-width: unset;
    padding: 71px 20px;
    width: 100%;
    gap: 25px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    gap: 36px;
  }
`

const ViewMore = styled.button`
  background: transparent;
  border: none;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  color: ${({ theme }) => theme.lightBlue};
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transform: ${({ inView }) => (inView ? "scale(1)" : "scale(0)")};
  transition: all 1s ease;
  &:hover {
    text-decoration: underline;
  }
`

const BlogPage = props => {
  const {
    data: {
      allContentfulBlog: { nodes },
    },
  } = props

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: true,
  })
  const { ref: titleRef, inView: titleInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })

  const filteredNodes = useMemo(() => {
    return filterDummyBlog(nodes)
  }, [nodes])

  const [slicedData, handleOnViewMore, slicedDataLength] = useViewMore(
    filteredNodes,
    6
  )

  return (
    <>
      <Seo title="BlogTemplate" />
      <LayoutRoot>
        <BlogPageContainer>
          <FlexContainer padding="81px 0 0">
            <PageTitle ref={titleRef} inView={titleInView}>
              LATEST NEWS
            </PageTitle>
          </FlexContainer>
          <BlogsContainer>
            <BlogsInnerContainer flexWrap tabletFlexWrap>
              {slicedData.map(
                ({
                  id,
                  blogTitle,
                  shortDescription,
                  createdAt,
                  coverImage,
                }) => (
                  <BlogCard
                    key={id}
                    id={id}
                    title={blogTitle}
                    content={shortDescription}
                    fluid={coverImage.fluid}
                    createdAt={createdAt}
                    margin="0 0 71px 0"
                  />
                )
              )}
            </BlogsInnerContainer>
            {slicedData.length > 6 ||
              (slicedDataLength === filteredNodes.length ? null : (
                <ViewMore
                  onClick={() => handleOnViewMore(prev => prev + 3)}
                  ref={ref}
                  inView={inView}
                >
                  View More
                </ViewMore>
              ))}
          </BlogsContainer>
        </BlogPageContainer>
      </LayoutRoot>
    </>
  )
}

export default BlogPage

export const query = graphql`
  {
    allContentfulBlog(sort: { fields: createdAt, order: DESC }) {
      nodes {
        blogTitle
        id
        createdAt(formatString: "MMMM DD YYYY")
        shortDescription
        coverImage {
          fluid(quality: 100) {
            src
            base64
            aspectRatio
            srcSet
            sizes
          }
        }
      }
    }
  }
`
